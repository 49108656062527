import type { ComponentProps } from 'react'

import NextLink from 'next/link'

import { chakra } from '@chakra-ui/react'

import { useNavigationalTabListStyles } from './NavigationalTabList'

type NavigationTabProps = {
  isActive?: boolean
  isDisabled?: boolean
} & ComponentProps<typeof NextLink>

/** Must be nested inside NavigationTabList component, uses styles provided by that component. */
export const NavigationTab = ({
  isActive,
  isDisabled,
  children,
  ...props
}: NavigationTabProps) => {
  const styles = useNavigationalTabListStyles()

  if (isDisabled) {
    return (
      <chakra.a
        aria-disabled
        __css={styles.tab}
        alignItems="center"
        display="inline-flex"
      >
        {children}
      </chakra.a>
    )
  }

  return (
    <chakra.link
      __css={styles.tab}
      aria-selected={isActive}
      {...props}
      as={NextLink}
    >
      {children}
    </chakra.link>
  )
}
