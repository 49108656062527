import { AbilityBuilder, createMongoAbility } from '@casl/ability'

import { type AuthedPrincipal } from '~/server/modules/auth/auth.types'

import { buildOrganisationAbility } from './abilities/organisation'
import { type AppAbility } from './authz.types'

export function defineAbilityFor(principal: AuthedPrincipal): AppAbility {
  const builder = new AbilityBuilder<AppAbility>(createMongoAbility)

  buildOrganisationAbility(principal, builder)

  return builder.build({
    detectSubjectType: (object) => object.__typename,
  })
}
