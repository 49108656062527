import { KASM_ORG_NAME, SPORTSG_ORG_NAME } from '@activesg/constants'

import { ALL_ACTIONS } from '../authz.constants'
import { type AdminAbilityBuilderFn } from '../authz.types'

const buildSportSgAbility: AdminAbilityBuilderFn = (principal, builder) => {
  builder.can(ALL_ACTIONS, 'Activity')
  builder.can(ALL_ACTIONS, 'Zone')
  builder.can(ALL_ACTIONS, 'Membership')
  return builder
}

const buildKasmAbility: AdminAbilityBuilderFn = (principal, builder) => {
  builder.can('read', 'Activity')
  builder.can('read', 'Zone')
  builder.can('read', 'Membership')
  return builder
}

const buildAdminAbility: AdminAbilityBuilderFn = (principal, builder) => {
  builder.can('read', 'Ledger')

  builder.can(ALL_ACTIONS, 'Corporation')
  builder.can(ALL_ACTIONS, 'BookingBallot')
  builder.can(ALL_ACTIONS, 'SportsCentre', {
    organisationId: principal.organisation.id,
  })
  builder.can(ALL_ACTIONS, 'Venue', {
    organisationId: principal.organisation.id,
  })
  builder.can(ALL_ACTIONS, 'Booking', {
    organisationId: principal.organisation.id,
  })
  builder.can(ALL_ACTIONS, 'Template', {
    organisationId: principal.organisation.id,
  })
  builder.can(ALL_ACTIONS, 'FacilityClosure', {
    organisationId: principal.organisation.id,
  })
  builder.can(ALL_ACTIONS, 'Programme', {
    organisationId: principal.organisation.id,
  })
  builder.can(ALL_ACTIONS, 'CheckoutSession')
  builder.can('read', 'Credit')

  // FIXME: This should be deprecated after proper rbac, its only for Whitelisting of Principals
  if (
    principal.email.endsWith('@open.gov.sg') ||
    ['tay_meng_hao@sport.gov.sg', 'xu_kewei@sport.gov.sg'].includes(
      principal.email.toLowerCase(),
    )
  ) {
    builder.can(ALL_ACTIONS, 'Principal', {
      organisationId: principal.organisation.id,
    })
  }

  if (
    principal.email.endsWith('@open.gov.sg') ||
    [
      'tay_meng_hao@sport.gov.sg',
      'xu_kewei@sport.gov.sg',
      'tan_kang_jin@sport.gov.sg',
    ].includes(principal.email.toLowerCase())
  ) {
    builder.can(ALL_ACTIONS, 'Credit')
  }
  builder.can(ALL_ACTIONS, 'Pass')
  builder.can(ALL_ACTIONS, 'ConsumePass')
  return builder
}

const buildReadonlyPassAbility: AdminAbilityBuilderFn = (
  _principal,
  builder,
) => {
  builder.can('read', 'Pass')
  builder.can(ALL_ACTIONS, 'CheckoutSession')
  builder.can(ALL_ACTIONS, 'ConsumePass')

  return builder
}

const buildConsumePassAbility: AdminAbilityBuilderFn = (principal, builder) => {
  builder.can('read', 'Venue', {
    organisationId: principal.organisation.id,
  })
  builder.can('update', 'ConsumePass')
  return builder
}

// Actions that are allowed for all organisations
export const buildOrganisationAbility: AdminAbilityBuilderFn = (
  principal,
  builder,
) => {
  const tempRoles = principal.whitelistedInfo
    ? principal.whitelistedInfo.tempRoles
    : []

  if (tempRoles.includes('SUPER_ADMIN')) {
    // TODO/FIXME: Rework this when RBAC is out, this if else is just meant for passes admin only, ideally policies should be nicely "incremental" and mutually exclusive, but there may be some overlaps here
    buildAdminAbility(principal, builder)

    switch (principal.organisation.name) {
      // Using name instead of ID since different environments may have different IDs
      case SPORTSG_ORG_NAME:
        buildSportSgAbility(principal, builder)
        break
      case KASM_ORG_NAME:
        buildKasmAbility(principal, builder)
        break
      default:
      // No ability
    }
  }

  if (tempRoles.includes('READ_PASS')) {
    buildReadonlyPassAbility(principal, builder)
  }

  if (tempRoles.includes('CONSUME_PASS')) {
    buildConsumePassAbility(principal, builder)
  }

  return builder
}
